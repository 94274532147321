import {
    ref, watch, onUnmounted,
    getCurrentInstance,
  } from '@vue/composition-api';
  import store from '@/store';
  import toast from '@/utils/toast';
  import { isEnableLocationModule } from '@/auth/utils';
  import { useUtils as useI18nUtils } from '@core/libs/i18n';
import moment from 'moment';
  
  export default function useCheckEW() {

    // Use toast
    const toastification = toast();
    const { t } = useI18nUtils();
    const blankItem = {
      id: 0,
      apartment: null,
      floor: null,
      name: '',
      price: null,
      deposit: null,
      size: null,
      active: true,
    };
    const vm = getCurrentInstance().proxy;
  
    const item = ref(JSON.parse(JSON.stringify(blankItem)));
    const resetItem = () => {
      item.value = JSON.parse(JSON.stringify(blankItem));
    };
    const rows = ref([]);
  
    // Table Handlers
    const columns = [
      {
        label: t('Khu vực'),
        field: 'locationName',
      },
      {
        label: t('Tòa nhà'),
        field: 'apartmentName',
      },
      {
        label: t('Căn hộ'),
        field: 'roomName',
      },
      {
        label: t('Số cuối kỳ trước'),
        field: 'previousLastIndex',
      },
      {
        label: t('Số đầu kỳ này'),
        field: 'firstIndex',
      },
      {
        label: t('Số cuối kỳ này'),
        field: 'lastIndex',
      },
      {
        label: t('Tiêu thụ kỳ này'),
        field: 'deltaThisMonth',
      },
      {
        label: t('Tiêu thụ kỳ trước'),
        field: 'previousDelta',
      },
      {
        label: t('Trung bình 3 tháng trước đó'),
        field: 'averageDelta',
      },
      {
        label: t('Chênh lệch kỳ trước'),
        field: 'previousDiff',
      },
      {
        label: t('Chênh lệch trung bình 3 tháng'),
        field: 'averageDiff',
      },
      {
        label: t('Tình trạng hợp đồng'),
        field: 'contractStatus',
      },
      {
        label: t('Cảnh báo'),
        field: 'note',
      },

      
    ];
  
    // filter
    const apartment = ref(null);
    const location = ref(null);
    const month = ref(moment(new Date()).format("MM-YYYY"));
    const meterType = ref({ title: 'Công tơ điện', value: 'electric', id: 'electric' })
    // current selected rows
    const selectedRows = ref([]);
    // ssr
    const isLoading = ref(false);
    const totalRecords = ref(0);
    const serverParams = ref({
      searchTerm: '',
      filter: {
        month: month.value,
        meterType: meterType.value.id
      },
      sort: {},
      page: 1,
      perPage: 5000,
    });
  
    //   API Call
    const fetchRooms = () => {
      isLoading.value = true;
      store
        .dispatch('report/getReportCheckEW', serverParams.value)
        .then(response => {
          const items = response.data
          totalRecords.value = items.length;
          rows.value = items;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = false;
        });
    };
  
    const fetchData = () => {
      fetchRooms();
    };
  
    const exportData = () => {
      store
        .dispatch('report/exportReportCheckEW', serverParams.value)
        .then(response => {
          vm.$router.push({ name: 'jobs' });
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    };
  
    //   Methods
    const onViewDetailItem = val => {
      item.value = val;
    };
    const updateParams = newProps => {
      serverParams.value = { ...serverParams.value, ...newProps };
      fetchData();
    };
  
    const onPageChange = params => {
      updateParams({ page: params.currentPage });
    };
  
    const onPerPageChange = params => {
      updateParams({ perPage: params.currentPerPage });
    };
  
    const onSortChange = params => {
      updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    };
  
    const onColumnFilter = params => {
      updateParams(params);
    };
  
    const selectionChanged = params => {
      selectedRows.value = params.selectedRows;
    };
  
    //   Watch
    watch(apartment, val => {
      const { filter } = serverParams.value;
      if (val) {
        filter.apartmentId = val.id;
      } else {
        delete filter.apartmentId;
      }
      updateParams({ filter });
    });
    watch(location, val => {
      const { filter } = serverParams.value;
      if (val) {
        filter.locationId = val.id;
      } else {
        delete filter.locationId;
      }
      updateParams({ filter });
    });
    watch(meterType, val => {
      const { filter } = serverParams.value;
      if (val) {
        filter.meterType = val.id;
      } else {
        delete filter.meterType;
      }
      updateParams({ filter });
    });
    watch(month, val => {
      const { filter } = serverParams.value;
      if (val) {
        filter.month = val;
      } else {
        delete filter.month;
      }
      updateParams({ filter });
    });
  
    return {
      item,
      columns,
      rows,
      apartment,
      month,
      meterType,
      selectedRows,
      isLoading,
      totalRecords,
      serverParams,
      location,
      isEnableLocationModule,
      fetchData,
      fetchRooms,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onViewDetailItem,
      exportData,
      t,
    };
  }
  